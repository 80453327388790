import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const projects = {
  heroText:
    'From interactive tools through printed publications to spacial art installations, if data visualization is' +
    ' involved – we’re in.',
  items: [
    {
      title: 'Beautiful News',
      scope: 'design / data visualization',
      description:
        'Series of infographics and charts, created for Information Is Beautiful and published daily to spread positive news and uplifting facts.',
      url: 'BeautifulNews',
      imgUrl: '/projects/beautiful-news.png',
      img: (
        <StaticImage
          src="../../static/projects/beautiful-news.png"
          alt="beautiful news screenshot"
          placeholder="tracedSVG"
          quality={100}
          layout="fullWidth"
          objectPosition="left top"
          objectFit="cover"
        />
      ),
      img1: (
        <StaticImage
          src="../../static/projects/beautiful-news.png"
          alt="beautiful news screenshot"
          placeholder="tracedSVG"
          layout="fixed"
          quality={100}
          height={500}
        />
      ),
      img2: (
        <StaticImage
          src="../../static/projects/beautiful-news-2.png"
          alt="beautiful news screenshot"
          placeholder="tracedSVG"
          backgroundColor="#A1A5B5"
          layout="constrained"
          quality={100}
          height={355}
        />
      ),
      img3: (
        <StaticImage
          src="../../static/projects/beautiful-news-3.png"
          alt="beautiful news screenshot"
          placeholder="tracedSVG"
          layout="constrained"
          quality={100}
          height={390}
        />
      ),
    },
    {
      title: 'Italo Calvino: text & data',
      scope: 'design / analysis / production',
      url: 'ItaloCalvino',
      description:
        'A book about a book, or a visual investigation based on text analysis of Italo Calvino’s novel<em>&nbsp;If on a winter’s night a traveler.</em>',
      imgUrl: '/projects/italo-calvino.png',
      img: (
        <StaticImage
          src="../../static/projects/italo-calvino.png"
          alt=""
          placeholder="tracedSVG"
          quality={100}
          layout="fullWidth"
          objectPosition="left top"
          objectFit="cover"
        />
      ),
      img1: (
        <StaticImage
          src="../../static/projects/italo-calvino-1.png"
          alt=""
          placeholder="tracedSVG"
          layout="constrained"
          quality={100}
          height={500}
          width={735}
        />
      ),
      img2: (
        <StaticImage
          src="../../static/projects/italo-calvino-2.jpg"
          alt=""
          placeholder="tracedSVG"
          layout="constrained"
          quality={100}
          height={390}
          width={500}
        />
      ),
      img3: (
        <StaticImage
          src="../../static/projects/italo-calvino-3.png"
          alt=""
          placeholder="tracedSVG"
          layout="constrained"
          quality={100}
          width={900}
          height={390}
        />
      ),
    },
    {
      title: 'ERGO Hestia Annual Report',
      scope: 'design / analysis / art direction',
      url: 'ErgoHestia',
      description:
        'Award-winning publication, with data visualization at its core, depicting diverse phenomena and processes within the company.',
      imgUrl: '/projects/ergo-hestia.jpg',
      img: (
        <StaticImage
          src="../../static/projects/ergo-hestia.jpg"
          alt=""
          placeholder="tracedSVG"
          layout="fullWidth"
          quality={100}
          objectPosition="left top"
          objectFit="cover"
        />
      ),
      img1: (
        <StaticImage
          src="../../static/projects/ergo-hestia-1.jpg"
          alt=""
          placeholder="tracedSVG"
          layout="fixed"
          quality={100}
          width={736}
          height={500}
        />
      ),
      img2: (
        <StaticImage
          src="../../static/projects/ergo-hestia-2.jpg"
          alt=""
          placeholder="tracedSVG"
          backgroundColor="#A1A5B5"
          layout="fixed"
          quality={100}
          width={500}
          height={390}
        />
      ),
      img3: (
        <StaticImage
          src="../../static/projects/ergo-hestia-3.jpg"
          alt=""
          placeholder="tracedSVG"
          quality={100}
          layout="constrained"
          width={900}
          height={390}
        />
      ),
    },
    {
      title: 'Time Capsule',
      scope: 'design / development',
      url: 'TimeCapsule',
      description:
        'Website created for OffOpera Festival 2021 with immersive, generative animation and an archive of “letters to the future” in form of text, images, sounds and videos.',
      imgUrl: '/projects/off-opera.jpg',
      img: (
        <StaticImage
          src="../../static/projects/off-opera.jpg"
          alt=""
          placeholder="tracedSVG"
          quality={100}
          layout="fullWidth"
          objectPosition="left top"
          objectFit="cover"
        />
      ),
      img1: (
        <StaticImage
          src="../../static/projects/off-opera-1.png"
          alt=""
          placeholder="tracedSVG"
          layout="fixed"
          quality={100}
          width={736}
          height={500}
        />
      ),
      img2: (
        <StaticImage
          src="../../static/projects/off-opera-2.png"
          alt=""
          placeholder="tracedSVG"
          layout="fixed"
          quality={100}
          width={500}
          height={390}
        />
      ),
      img3: (
        <StaticImage
          src="../../static/projects/off-opera-3.png"
          alt=""
          placeholder="tracedSVG"
          layout="fixed"
          quality={100}
          width={750}
          height={400}
        />
      ),
    },
    {
      title: 'DrWhy Explainers',
      scope: 'design / data visualization',
      url: 'DrWhy',
      description:
        'A set of tools demonstrating different aspects of predictive Machine Learning models and their performance.',
      imgUrl: '/projects/drWhy.jpg',
      img: (
        <StaticImage
          src="../../static/projects/drWhy.jpg"
          alt=""
          quality={100}
          layout="fullWidth"
          objectPosition="left top"
          objectFit="cover"
        />
      ),
    },
    {
      title: 'Ursus Factory mural',
      scope: 'data art / installation',
      url: 'UrsusFactory',
      description:
        'Hand-drawn mural at the Centre for Contemporary Art in Warsaw documenting seven years of the "Ursus Zakłady" activist project.',
      imgUrl: '/projects/ursus.png',
      img: (
        <StaticImage
          src="../../static/projects/ursus.png"
          alt=""
          placeholder="tracedSVG"
          quality={100}
          layout="fullWidth"
          objectPosition="left top"
          objectFit="cover"
        />
      ),
    },
    {
      title: 'DrWhy Identity',
      scope: 'design / branding',
      url: 'DrWhyIdentity',
      description:
        'Identity for DrWhy.AI collection and its R packages, based on a set of generative logos.',
      imgUrl: '/projects/drWhyIdentity.jpg',
      img: (
        <StaticImage
          src="../../static/projects/drWhyIdentity.jpg"
          alt=""
          placeholder="tracedSVG"
          quality={100}
          layout="fullWidth"
          objectPosition="left top"
          objectFit="cover"
        />
      ),
    },
  ],
};
