import * as React from 'react';
import styled from 'styled-components';
import { Box, Heading, Flex } from 'rebass/styled-components';
import { Row } from '@/components/Row';
import { styledTheme } from '@/styledTheme';
import { Text } from '@/components/Text';
import { ContentWrapper } from '@/components/ContentWrapper';
import { Desktop } from '@/components/Desktop';
import { Mobile } from '@/components/Mobile';
import { Link } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

export const ProjectTout = ({
  title,
  scope,
  description,
  img,
  url,
  isHome,
  imgUrl,
}) => {
  const location = useLocation();
  const isWork = location.pathname === '/work';
  return (
    <Box maxWidth="100vw" style={{ overflow: 'hidden' }}>
      <DesktopWrapper isWork={isWork}>
        <hr style={{ marginBottom: 0 }} />
        <ContentWrapper maxWidth="1000px">
          <ProjectWrapper
            flexProps={{
              mb: [0],
            }}
            left={
              <ProjectDescription
                title={title}
                scope={scope}
                description={description}
                url={url}
                isHome={isHome}
              />
            }
            right={
              <Box as={Link} to={`/work/${url}`}>
                {/* <ImageWrapper>{img}</ImageWrapper> */}
                <ImageWrapper>
                  <img src={imgUrl} alt="" />
                </ImageWrapper>
              </Box>
            }
          />
        </ContentWrapper>
      </DesktopWrapper>

      <MobileWrapper isWork={isWork}>
        <ContentWrapper>
          <ImageWrapper
            mx={['-20px', '-20px', 0]}
            style={{ overflow: 'hidden' }}
            as={Link}
            to={`/work/${url}`}
          >
            {img}
          </ImageWrapper>
          <ProjectDescription
            title={title}
            scope={scope}
            description={description}
            url={url}
            isHome={isHome}
          />
        </ContentWrapper>
      </MobileWrapper>
    </Box>
  );
};

const ProjectDescription = ({ title, scope, description, url, isHome }) => (
  <StyledBox
    mb={[4, 4, 'initial']}
    width={['auto', 'auto', 'auto', 300]}
    mt={['4px', '4px', 3]}
  >
    <ProjectTitle as={Link} to={`/work/${url}`}>
      {title}
    </ProjectTitle>
    <Flex mt={['1px', '1px', 0]}>
      <Text
        mt={['4px', '4px', '7px']}
        color={styledTheme.colors.secondary}
        fontFamily={styledTheme.fonts.heading}
        fontSize={['14px', '14px', '16px']}
        lineHeight={['24px']}
      >
        {scope}
      </Text>
    </Flex>
    {!isHome && (
      <Text
        mr={[3]}
        mt={['20px', '20px', '39px']}
        pr={[1]}
        lineHeight={['30px']}
        fontSize={['18px']}
      >
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </Text>
    )}
  </StyledBox>
);

const ImageWrapper = styled(Box)`
  img {
    margin-bottom: -5px;
    transition: all 0.5s ease !important;
    transform-origin: top left;

    &:hover {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    > div {
      margin: 0 -20px;
    }
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints[1]}) and (max-width: ${({ theme }) =>
      theme.breakpoints[2]}) {
    img {
      width: auto;
    }
  }
`;

const ProjectWrapper = styled(Row)`
  min-width: 100vw;
  max-height: 400px;

  @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
    max-height: 500px;
  }
  > div {
    flex-wrap: nowrap;
  }

  > div > div:nth-of-type(1) {
    min-width: 300px;
    max-width: 300px;
    @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
      margin-right: 30px;
    }
    @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
      max-height: 500px;
    }
  }

  > div > div:nth-of-type(2) {
    div {
    }
    @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
      max-height: 500px;
    }
  }
`;

const ProjectTitle = styled(Heading)`
  text-decoration: none;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${styledTheme.colors.primary};
  font-family: ${styledTheme.fonts.body};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 20px;
    line-height: 20px;
  }
`;

const DesktopWrapper = styled(Desktop)`
  max-width: 100vw;
  overflow: hidden;

  ${({ isWork }) =>
    !isWork &&
    `
    display: black;

    @media screen and (max-width: ${styledTheme.breakpoints[2]}) and (orientation: portrait) {
      display: none;
    }
  `};
`;

const MobileWrapper = styled(Mobile)`
  ${({ isWork }) =>
    !isWork &&
    `
      display: none;

      @media screen and (max-width: ${styledTheme.breakpoints[2]}) and (orientation: portrait) {
        display: block;
      }

      > div {
        width: 100%;

        > div {
          margin-bottom: 90px;
          padding-left: 20px;
      }
    `}
`;

const StyledBox = styled(Box)`
  @media screen and (min-width: ${styledTheme
      .breakpoints[1]}) and (max-width: ${styledTheme.breakpoints[2]}) {
    margin-top: 40px;
  }
`;
